import Api from '../Api';

export const getQueue = async (page = 1, maxResults = 30) => {
  try {
    const { data } = await Api.post('application.get-queues-converter', {
      page,
      maxResults
    });

    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
