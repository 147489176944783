import React, { useState } from 'react'
import {Form} from 'react-bootstrap'

import * as Styled from './styles'
export default function LogList({getLog}) {
  const [email, setEmail] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    getLog({email});
  };

  return (
    <Form onSubmit={onSubmit}>
      <Styled.SubmitButton variant="info" type="submit"> Buscar </Styled.SubmitButton>
    </Form>
  );
}
