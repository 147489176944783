import React, { useState, createContext } from 'react';
import Loader from '../components/Loader';
import Session from '../services/login/Session';
import Api from '../services/Api';

const AppContext = createContext({
  loading: () => null,
  user: null,
});
export const TOKEN_KEY = "compufacilConverterToken";

export const AppProvider = ({ children }) => {
  const [loading, setLoading] = useState({
    show: false,
    text: '',
  });
  const [user, setUser] = useState(Session.get(TOKEN_KEY));

  const state = {
    loading: (show, text) => {
      setLoading({
        show: show,
        text: text,
      })
    },
    user,
    login: async (email, password, history) => {
      const { data } = await Api.post('application.authenticate', {
        'login': email,
        'password': password,
        'converterAccess': true
      });
      await Session.set(TOKEN_KEY, data.access_token);
      setUser(data.access_token)
      history.push('/')
      return true;
    },
    logout: async () => {
      try {
        setLoading({ show: true })
        await setUser(null)
        await Session.remove(TOKEN_KEY)
        await Api.post('application.logout')
      } finally {
        setLoading({ show: false })
      }

    }
  };
  useState(async () => {
      const userLogged = await Session.get(TOKEN_KEY);
      if (userLogged) {
        setUser(userLogged);
        return;
      }
      setUser(false);
  }, [])

  return (
    <AppContext.Provider value={state}>
      <Loader show={loading?.show} text={loading?.text} />
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
