import styled from 'styled-components';
import {Button} from 'react-bootstrap';

export const Section = styled.section``;
export const Input = styled.input``;
export const Paragraph = styled.p``;

export const SubmitButton = styled(Button)`
  position: auto !important;
  margin-top: 15px;
  width: 150px;
`;
