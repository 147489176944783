import { renderToString } from 'react-dom/server'
import Swal from 'sweetalert2';

const ToastMessage = Swal.mixin({
  toast: true,
  position: 'bottom-right',
  showConfirmButton: false,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  }
});

export const MessageTypes =  {
  success: 'success',
  error: 'error',
  warning: 'warning',
};

export const Toast = (type, message, timer) => {
  setTimeout(() => ToastMessage.fire({
    icon: MessageTypes[type] || 'success',
    title: message,
    timer: timer ? 5000 : undefined
  }),  500);
};

export const ModalMessage = ({type, title, message, fullscreen}) => {
  Swal.fire({
    icon: MessageTypes[type] || 'success',
    title,
    html: typeof message === 'string' ? message : renderToString(message),
    grow: fullscreen ? 'fullscreen' : false,
  })
};
