import Api from '../Api';
import toCsv from 'jsonexport';
import ApiV2 from '../ApiV2';

export const login = async (payload) => {
  try {
    const { data } = await Api.post('application.authenticate', payload);

    return {
      success: true,
      data: data
    };
  } catch (err) {
    return {
      success: false,
      data: {}
    }
  }
};

export const loginV2 = async (payload) => {
  try {
    const { data } = await ApiV2.post('application.authenticate', { ...payload, isSharedAccess: true });

    return {
      success: true,
      data: data,
    };
  } catch (err) {
    return {
      success: false,
      data: {}
    }
  }
};

export const getPersonData = async ({ access_token, isClient, isSupplier }) => {
  try {
    const config = {
      headers: {
        'Authorization-Compufacil': access_token
      }
    };

    let payload = {};

    if (isClient) {
      payload.isClient = true;
    }

    if (isSupplier) {
      payload.isSupplier = true;
    }

    const { data } = await Api.post('person.get-person-conversor', payload, config);

    return data;
  } catch (err) {
    console.log(err)
    return [];
  }
};

export const getInventoryData = async ({ access_token, url, params = {} }) => {
  try {
    const config = {
      headers: {
        'Authorization-Compufacil': access_token
      }
    };

    const { data } = await Api.post(url, params, config);

    return data;
  } catch (err) {
    console.log(err)
    return [];
  }
};

export const parseData = (payload) => {

  if (payload.clients && payload.clients.length) {
    payload.clients = payload.clients.map((i) => {

      if (i.addressCollection && i.addressCollection.length) {
        i.addressCollection = i.addressCollection[0];
      }

      if (i.emailCollection && i.emailCollection.length) {
        i.emailCollection = i.emailCollection[0];
      }

      if (i.phoneCollection && i.phoneCollection.length) {
        i.phoneCollection = i.phoneCollection[0];
      }

      if (i.clientData && i.clientData.referenceCollection.length) {
        i.clientData.referenceCollection = i.clientData.referenceCollection[0];
      }

      if (i.clientData && i.clientData.contactCollection.length) {
        i.clientData.contactCollection = i.clientData.contactCollection[0];
      }

      return i;
    });
  }

  if (payload.suppliers && payload.suppliers.length) {
    payload.suppliers = payload.suppliers.map((i) => {

      if (i.addressCollection && i.addressCollection.length) {
        i.addressCollection = i.addressCollection[0];
      }

      if (i.emailCollection && i.emailCollection.length) {
        i.emailCollection = i.emailCollection[0];
      }

      if (i.phoneCollection && i.phoneCollection.length) {
        i.phoneCollection = i.phoneCollection[0];
      }
      return i;
    });
  }

  if (payload.products && payload.products.length) {
    payload.products = payload.products.map((i) => {
      if (
        typeof i.observation === 'string' &&
        i.observation.length === 1 &&
        i.observation.charCodeAt(0) === 13
      ) {
        i.observation = null
      }

      if (!i.fiscal) {
        return i;
      }

      i.fiscal.produto.PIS *= 100;
      i.fiscal.produto.COFINS *= 100;
      i.fiscal.produto.MVA *= 100;
      i.fiscal.produto.IPI *= 100;

      return i;
    });
  }

  return payload;
}

export const exportToCsv = (payload = []) => {
  const downloadCsv = (csv, fileName) => {
    const exportedFilenmae = `${fileName}.csv`;

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", exportedFilenmae);
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  try {
    Object.keys(payload).forEach((key) => {
      toCsv(payload[key], (err, csv) => {
        if (err) return console.error(err);
        downloadCsv(csv, key);
      });
    });
  } catch (err) {
    console.error(err);
  }
};
