import styled from "styled-components";
import { Colors } from '../../styles/theme';

export const Container = styled.div`
  background: ${Colors.green._500};
  color: #FFF;
  padding: 5px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.img`
  color: #FFF;
  max-height: 50px;
  width: auto;
  @media (max-width: 450px) {
    max-height: 30px;
  }
`;

export const Button = styled.button`
  color: ${Colors.green._500};
  font-weight: bold;
  max-height: 50px;
  background: #FFF;
  border: none;
  padding: 5px 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
  transition: .3ms ease-in-out;
  &:hover {
    opacity: 1;
  }
`;

export const ButtonLabel = styled.span`
  margin: 1px 10px 0 10px;
  display:block;
`;
