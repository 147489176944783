import React, { useContext, useState } from 'react'
import { Form, Toast } from 'react-bootstrap'

import * as Styled from './styles'
import { loginV2 } from '../../services/Export';
import AppContext from '../../context/AppContext';
import { MessageTypes } from '../../utils/Message';
import ApiV2 from '../../services/ApiV2';
export default function Export({ exportData }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [customersList, setCustomersList] = useState([]);
  const [dataLogin, setDataLogin] = useState({});
  const [uuid, setUuid] = useState('');
  const { loading } = useContext(AppContext);

  const onSubmit = async (e) => {
    e.preventDefault();

    loading(true, 'Buscando usuário...');

    const { success, data } = await loginV2({ login: email, password });

    if (!success) {
      loading(false);
      return Toast(MessageTypes.error, 'Error ao buscar o usuário', true);
    }

    setDataLogin(data);

    if (data.companies) {
      loading(false);
      setCustomersList(data.companies);
      setUuid(data.companies[0].uuid);
      return;
    }

    exportData(data);
  };


  const setSelectedClientId = async (uuid) => {
    try {
      loading(true, 'Buscando dados...');

      const { access_token } = dataLogin;
      const config = {
        headers: {
          'Authorization-Compufacil': access_token
        }
      };


      try {
        await ApiV2.post('application.authenticate-company', { uuid }, config);

      } catch (error) {
        loading(false);
        setCustomersList([]);
        return;
      }

      exportData(dataLogin);

    } catch (err) {
      console.log(err)
      return [];
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <Form.Control
        type="email"
        name="email"
        className="mb-2"
        placeholder="Informe o email"
        onChange={e => setEmail(e.target.value)}
        required
      />
      <Form.Control
        type="password"
        name="password"
        className="mb-2"
        placeholder="Informe a senha"
        onChange={e => setPassword(e.target.value)}
        required
      />
      {customersList.length > 0 && (
        <>
          <Form.Group>
            <Form.Control as="select" onChange={e => setUuid(e.target.value)}>
              {customersList.map((e) => <option value={e.uuid}>{e.name + ' - ' + e.applicationName}</option>)}
            </Form.Control>
          </Form.Group>
          <Styled.SubmitButton variant="info" type="button" onClick={() => setSelectedClientId(uuid)}> Exportar </Styled.SubmitButton>
        </>
      )}
      {customersList.length == 0 && (
        <Styled.SubmitButton variant="info" type="submit"> Exportar </Styled.SubmitButton>
      )}
    </Form>
  );
}
