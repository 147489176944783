import styled from 'styled-components';

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 15px 0;
  text-align: left;
`;

export const ListItem = styled.li`
  padding: 5px;
  margin: 4px 10px;
`;