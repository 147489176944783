import styled, { keyframes } from 'styled-components';
import { Colors } from "../../styles/theme";

const rotate = keyframes`
  100% {
      transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
`;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: #FFF;
  align-items: center;
  justify-content: center;
  display: flex;
  transition: all .5s ease-in-out;
  opacity: ${({show}) => show ? 0.8 : 0};
  z-index: ${({show}) => show ? 50 : -1};
  flex-direction: column;
`;

export const Svg = styled.svg`
  animation: ${rotate} 2s linear infinite;
  height: 100px;
  width: 100px;
  transform-origin: center center;
`;

export const Circle = styled.circle`
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke: ${Colors.green._500};
  animation: ${dash} 1.5s ease-in-out infinite;
  stroke-linecap: round;
`;

export const Text = styled.div`
  color: ${Colors.green._500};
  margin-top: 30px;
  font-size: 18px;
`;
