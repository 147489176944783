
import axios from 'axios';
import config from '../config';
import Session from '../services/login/Session';

export const TOKEN_KEY = "compufacilConverterToken";

const hoursToTimeout = 3;
const timeoutInMiliseconds = hoursToTimeout * 60 * 60 * 1000;

export default axios.create({
  baseURL: config.baseURL + 'v1/',
  headers: {
    'Authorization-Compufacil': Session.get(TOKEN_KEY) ?? '',
    'converter-authorization': config.authToken,
  },
  timeout: timeoutInMiliseconds
});
