import env from './env';

const config = {
  dev: {
    appEnv: 'Desenvolvimento',
    baseURL: 'http://app.clippfacil.local/rpc/',
    authToken: '2rbCs5d56zm9LzyXgEx6k46dd40c6e96',
  },
  homolog: {
    appEnv: 'Homologação',
    baseURL: 'https://app.homolog.clippfacil.com.br/rpc/',
    authToken: '2rbCs5d56zm9LzyXgEx6k46dd40c6e96',
  },
  prod: {
    appEnv: 'Produção',
    baseURL: 'https://api.clippfacil.com.br/rpc/',
    authToken: 'ajFqx0867a66Y2bce7df418xGZ684e79',
  },
};

export default config[env];
