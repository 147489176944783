import React, { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Form } from 'react-bootstrap'

import * as Styled from './styles'

export default function ImportFile({ saveData, clientId }) {
  const [file, setFile] = useState(null);

  const onDrop = useCallback(files => {
    setFile(files ? files[0] : null);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false, accept: '.csv' })

  const onSubmit = (e) => {
    e.preventDefault();
    saveData({ file, clientId });
  };

  return (
    <Form onSubmit={onSubmit}>
      <Styled.Section>
        <Styled.Dropzone {...getRootProps({ className: 'dropzone' })}>
          <Styled.Input name="file" {...getInputProps()} />
          <Styled.Paragraph>
            {
              !file ? 'Solte o arquivo aqui ou clique para selecionar' : file.name
            }
          </Styled.Paragraph>
        </Styled.Dropzone>
      </Styled.Section>
      <Styled.SubmitButton variant="info" type="submit"> Importar </Styled.SubmitButton>
    </Form>
  );
}
