import React from 'react';

import {
  Container,
  Svg,
  Circle,
  Text
} from './styles';

const Loader = ({ show = false, text }) => (
  <Container show={show}>
    <Svg viewBox="25 25 50 50">
      <Circle
        cx="50"
        cy="50"
        r="20"
        fill="none"
        strokeWidth="2px"
        strokeMiterlimit="10"
      />
    </Svg>

    <Text>{text}</Text>
  </Container>
);

export default Loader;
