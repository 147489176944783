import Api from '../Api';

export const hasProduct = async (clientId) => {
  const { data: { status } } = await Api.post('inventory.import-has-product', {
    clientId,
  });
  return status;
};

export const importFile = async ({clientId, file}) => {
  const form  = new FormData();
  form.append('queue', 'Importação de Produtos');
  form.append('clientId', clientId);
  form.append('file', null);

  if(file) {
    form.append('file', file, file.name);
  }

  const { data } = await Api.post('inventory.import-products', form);
  return data;
};
