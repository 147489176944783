import styled from 'styled-components';
import { Colors } from '../../styles/theme';

import {
  Button,
  Row as RowElement,
  Col as ColElement,
  Form as FormElement,
  FormGroup as FormGroupElement,
  FormControl as FormControlElement,
} from 'react-bootstrap'

export const Container = styled.div`
  padding: 60px 50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  
  @media (max-width: 575px) {
    padding: 60px 10px !important;
  }
`;

export const Row = styled(RowElement)`
  width: 100%;
`;

export const Col = styled(ColElement).attrs({
  lg: 6,
  md: 6,
  sm: 6,
  xs: 12,
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  &:first-child {
    align-items: flex-end;
    border-right: 1px solid #aab2bc;
  }
  
  @media (max-width: 575px) {
    align-items: center;
    justify-content: center;
    width: 100%;
    &:first-child {
      justify-content: center;
      align-items: center;
      border: none;
      margin-bottom: 50px;
    }
  }
`;

export const Logo = styled.img`
  padding: 0;
  margin: 0;
  max-width: 300px;
  @media (max-width: 600px) {
     max-width: 250px;
  }
`;

export const SubTitle = styled.h2`
  color: #7e7e7e;
  font-size: 22px;
  padding: 0;
  line-height: 0;
  margin: -15px 12px 0 0;
  position: relative;
  
  @media (max-width: 600px) {
    margin: -10px 8px 0 0;
  }
    
  @media (max-width: 575px) {
    margin: -5px 0 0 0;
  }
`;

export const Form = styled(FormElement)`
  max-width: 280px;
  box-sizing: border-box;
  @media (min-width: 575px) and (max-width: 650px) {
    max-width: 250px;
  }
`;

export const FormGroup = styled(FormGroupElement)`
  margin: 0 0 0 20px;
  @media (max-width: 575px) {
    margin: 0;
  }
`;

export const FormControl = styled(FormControlElement)`
  && {
    background-color: transparent;
    display: block;
    margin: 15px 0;
    padding: 10px;
    height: 50px;
    border: 1px solid #7b8088;
    border-radius: 4px;
    width: 280px;
    color: #495965;
    font-size: 16px;
  }
  
  &&:hover, &&:focus {
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border-color: ${Colors.green._500};
  }
  
  @media (min-width: 575px) and (max-width: 650px) {
    && {
      width: 250px;
    }
  }
`;

export const SubmitButton = styled(Button)`
  width: 100%;
  margin: 20px 0 0 20px;
  padding: 10px;
  height: 50px;
  background: ${Colors.green._500} !important;
  border-color: ${Colors.green._500} !important;
  box-shadow: none;
  outline: none !important;
  &:hover, &:focus, &:active {
    outline: none !important;
    box-shadow: none !important;
  }
  @media (max-width: 575px) {
    margin: 20px 0 0 0;
  }
`;
