import React from 'react';
import GoogleFontLoader from 'react-google-font-loader';
import Routes from "./Routes";
import { AppProvider } from './context/AppContext';
import GlobalStyle from './styles/global.js';

function App() {
  return (
    <AppProvider>
        <GoogleFontLoader
            fonts={[
                { font: 'Roboto', weights: [400, 700] },
            ]}
        />
        <GlobalStyle />
        <Routes/>
    </AppProvider>
  );
}

export default App
