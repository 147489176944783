import styled from 'styled-components';

export const Section = styled.section``;

export const Container = styled.div`
  padding: 0 !important;
`;

export const CardBody = styled.div`
  padding: 10px;
`;

export const CardTitle = styled.h3`
  font-weight: 600;
  padding: 10px 25px;
  cursor: default;
`;

export const Card = styled.div`
  float: left;
  padding: 0 10px;
  width: 32%;
  margin-left: 1%;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 2px 3px 8px rgba(0,0,0,0.07);
  height: 250px;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: default;
`;

export const CardWide = styled.div`
  float: left;
  margin-left: 1%;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 2px 3px 8px rgba(0,0,0,0.07);
  min-height: 150px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 98%;
`;

export const CardHeader = styled.div`
  position: relative;
  border-bottom: 1px dashed #E8E8E8;
  border-radius: 3px 3px 0 0;
  transition: all 0.5s;
  overflow: hidden;
  padding: 0 !important;

  &:before {
    content: '';
    width: 4px;
    height: 100%;
    position: absolute;
    left: 0;
    background: #f00;
    top: 0;
    border-left: 7px solid #008DDE;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 26px;
  font-weight: bold;
`;


export const ClearFix = styled.div`
  display: flex;
  padding: 20px;
  width: 100%
`;
