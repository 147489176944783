import React from 'react';
import Moment from 'react-moment';
import Span from '../Span'

import {
  List,
  ListItem
} from './styles';

const ListItemLog = ({ items }) => (
  <List>
    {items && items.map(item => (
      <ListItem key={item.id}>{item.log} - <Span errorType={item.errorType}>
      </Span> - <Moment format="DD/MM/YYYY">{item.created}</Moment></ListItem>
      ))}
   </List>
);

export default ListItemLog;
