import React, { useState, useContext } from 'react'
import * as Styled from './styles'
import Confirm from '../../utils/Confirm'
import Header from '../../components/Header'
import AppContext from '../../context/AppContext'
import ImportFile from '../../components/ImportFile'
import Export from '../../components/Export'
import LogList from '../../components/LogList'
import Customers from '../../components/Customers'
import ListItemLog from '../../components/ListItemLog'
import Queue from '../../components/Queue'
import { Toast, MessageTypes } from '../../utils/Message'
import { importFile, hasProduct } from '../../services/Inventory'
import { importFileClients, importFileSuppliers, hasSuppliers, hasClients } from '../../services/Person'
import { login, getPersonData, getInventoryData, parseData, exportToCsv } from '../../services/Export'
import { getLog } from '../../services/Log'

function Dashboard() {
  const { loading } = useContext(AppContext);
  const [logList, setLogList] = useState([]);
  const [queueRefresh, setQueueRefresh] = useState(false);
  const [clientId, setClientId] = useState(null);

  const importData = async (form, validationCallback, recursiveCallback, importCallback, ressource = 'arquivo') => {
    const { clientId, file } = form;

    if (clientId == null) {
      Toast(MessageTypes.error, 'Selecione um cliente', true);
      return;
    }

    if (!file) {
      Toast(MessageTypes.error, 'Selecione um arquivo!', true);
      return;
    }

    loading(true, `Importando ${ressource}...`);

    try {
      const hasData = await validationCallback(clientId);

      if (hasData) {
        loading(false);
        const confirm = await Confirm({
          message: 'Já existem informações cadastradas, deseja continuar mesmo assim?',
          actionOk: () => recursiveCallback(form, true)
        });

        if (!confirm.value) {
          return;
        }
      }

      loading(true, `Importando ${ressource}...`);

      await importCallback(form);
      setQueueRefresh(!queueRefresh);

      const capitalized = ressource[0].toUpperCase() + ressource.slice(1).toLowerCase();
      Toast(MessageTypes.success, `${capitalized} enviados para a fila com sucesso`);
    } catch (e) {
      Toast(MessageTypes.error, e?.response?.data?.error_message || `Error ao enviar ${ressource} para a fila`);
    } finally {
      loading(false);
    }
  }

  const inventoryImport = async (form) => {
    return importData(form, hasProduct, inventoryImport, importFile, 'produtos');
  };

  const supplierImport = async (form) => {
    return importData(form, hasSuppliers, supplierImport, importFileSuppliers, 'fornecedores');
  };

  const clientImport = async (form) => {
    return importData(form, hasClients, clientImport, importFileClients, 'clientes');
  };

  const exportData = async (data) => {
    loading(true, 'Gerando CSVs...');

    const { access_token } = data;

    const [
      clients,
      suppliers,
      products,
      groups,
      purchase,
      estimate,
      sale,
      payment_mode
    ] = 
    await Promise.all([
      getPersonData({ access_token, isClient: true }),
      getPersonData({ access_token, isSupplier: true }),
      getInventoryData({
        access_token,
        url: 'inventory.get-product',
        params: { fiscal: true, isConversor: true }
      }),
      getInventoryData({ access_token, url: 'inventory.get-group' }),
      getInventoryData({ access_token, url: 'inventory.get-purchase' }),
      getInventoryData({ access_token, url: 'inventory.get-estimate' }),
      getInventoryData({ access_token, url: 'inventory.get-sale' }),
      getInventoryData({ access_token, url: 'inventory.get-payment_mode' })
    ])
    
    let toExport = {
      clients,
      suppliers,
      products,
      groups,
      purchase,
      estimate,
      sale,
      payment_mode
    }
    exportToCsv(parseData(toExport));
    loading(false);
  };

  const getLogList = async () => {
    if (clientId == null) {
      Toast(MessageTypes.error, 'Selecione um cliente', true);
      return;
    }
    setLogList([])
    loading(true, 'Buscando logs da conversão...');

    try {
      const hasLog = await getLog(clientId);
      if (hasLog) {
        setLogList(hasLog)
        loading(false);
        Toast(MessageTypes.success, 'Verifique o log pois encontramos alguns erros');
        return
      }

      Toast(MessageTypes.success, 'A conversão foi realizado com sucesso');
    } catch (e) {
      Toast(MessageTypes.error, e?.response?.data?.error_message || 'Error ao enviar produtos para a fila');
    } finally {
      loading(false);
    }
  };

  return (
    <Styled.Container>
      <Header />
      <Styled.Title>Painel de Conversão</Styled.Title>

      <Styled.Card>
        <Styled.CardHeader>
          <Styled.CardTitle>Converter</Styled.CardTitle>
        </Styled.CardHeader>
        <Styled.CardBody>
          <Customers setSelectedClientId={setClientId}></Customers>
        </Styled.CardBody>
      </Styled.Card>

      {clientId !== null && (
        <>
          <Styled.Card>
            <Styled.CardHeader>
              <Styled.CardTitle>Importar Produtos</Styled.CardTitle>
            </Styled.CardHeader>
            <Styled.CardBody>
              <ImportFile saveData={inventoryImport} clientId={clientId} />
            </Styled.CardBody>
          </Styled.Card>

          <Styled.Card>
            <Styled.CardHeader>
              <Styled.CardTitle>Importar Clientes</Styled.CardTitle>
            </Styled.CardHeader>
            <Styled.CardBody>
              <ImportFile saveData={clientImport} clientId={clientId} />
            </Styled.CardBody>
          </Styled.Card>

          <Styled.Card>
            <Styled.CardHeader>
              <Styled.CardTitle>Importar Fornecedores</Styled.CardTitle>
            </Styled.CardHeader>
            <Styled.CardBody>
              <ImportFile saveData={supplierImport} clientId={clientId} />
            </Styled.CardBody>
          </Styled.Card>

          <Styled.Card>
            <Styled.CardHeader>
              <Styled.CardTitle>Log Conversão</Styled.CardTitle>
            </Styled.CardHeader>
            <Styled.CardBody>
              <LogList getLog={getLogList} clientId={clientId}></LogList>
              <Styled.Section>
                <ListItemLog items={logList}></ListItemLog>
              </Styled.Section>
            </Styled.CardBody>
          </Styled.Card>
        </>
      )}

      <Styled.Card>
        <Styled.CardHeader>
          <Styled.CardTitle>Exportar Dados de Conta</Styled.CardTitle>
        </Styled.CardHeader>
        <Styled.CardBody>
          <Export exportData={exportData}></Export>
        </Styled.CardBody>
      </Styled.Card>

      <Styled.CardWide>
        <Styled.CardHeader>
          <Styled.CardTitle>Lista de Conversões</Styled.CardTitle>
        </Styled.CardHeader>
        <Styled.CardBody>
          <Queue refresh={queueRefresh}></Queue>
        </Styled.CardBody>
      </Styled.CardWide>

      <Styled.ClearFix />

    </Styled.Container>
  );
}

export default Dashboard;
