import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import React, { useContext } from 'react'
import userContext from './context/AppContext';

import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";

export const TOKEN_KEY = "compufacilConverterToken";
const AdminRoute = (props) => {
    const { user } = useContext(userContext);
    if (!user) {
      return <Redirect to="/login" />;
    }
    return <Route {...props} />
  };

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route path="/login" component={Login} />
            <AdminRoute path="/" component={Dashboard} />
            <Route path="*" component={() => <h1>Page not found</h1>} />
        </Switch>
    </BrowserRouter>
);

export default Routes;
