import Api from '../Api';

export const importFileClients = async ({clientId, file}) => {
  const form  = new FormData();
  form.append('queue', 'Importação de Clientes');
  form.append('clientId', clientId);
  form.append('file', null);

  if(file) {
    form.append('file', file, file.name);
  }

  const { data } = await Api.post('person.import-clients', form);
  return data;
};

export const hasClients = async (clientId) => {
  const { data: { status } } = await Api.post('person.import-has-clients', {
    clientId,
  });
  return status;
};

export const hasSuppliers = async (clientId) => {
  const { data: { status } } = await Api.post('person.import-has-suppliers', {
    clientId,
  });
  return status;
};

export const importFileSuppliers = async ({clientId, file}) => {
  const form  = new FormData();
  form.append('queue', 'Importação de Fornecedores');
  form.append('clientId', clientId);
  form.append('file', null);

  if(file) {
    form.append('file', file, file.name);
  }

  const { data } = await Api.post('person.import-suppliers', form);
  return data;
};
