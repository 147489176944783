import React, { useContext } from 'react';
import { FaPowerOff } from 'react-icons/fa';

import AppContext from '../../context/AppContext';

import {
  Container,
  Logo,
  Button,
  ButtonLabel
} from './styles';

import logo from '../../assets/marca.png';

const Header = ( ) => {
  const { logout } = useContext(AppContext);
  return(
    <Container>
      <Logo src={logo} />
      <Button onClick={logout}>
        <FaPowerOff />
        <ButtonLabel>
          Sair
        </ButtonLabel>
      </Button>
    </Container>
  )
};

export default Header;
