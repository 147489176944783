import styled from 'styled-components';

export const SpanProcessing = styled.span`
  color: #FFF;
  background-color: blue;
  padding: 5px;
  border-radius: 5px;
`;

export const SpanSuccess = styled.span`
  color: #FFF;
  background-color: green;
  padding: 5px;
  border-radius: 5px;
`;

export const TdLog = styled.td`
  white-space: pre-wrap;
`;
