import Swal from 'sweetalert2';

export default async ({
    message = '',
    actionOk,
    actionCancel,
    textOk = 'Sim',
    textCancel = 'Não',
  }) => {
  return Swal.fire({
    title: '&nbsp;',
    html: message,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: textOk,
    cancelButtonText: textCancel,
  });
};
