import React, {useContext, useState} from 'react'
import * as Styled from './styles'
import AppContext from '../../context/AppContext';
import {Toast, MessageTypes} from '../../utils/Message';
import {Redirect} from "react-router-dom";
import logo from "../../assets/logo.png";

function Login({history}) {
  const {loading, login} = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function validateForm() {
    return email.length && password.length;
    ;
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    loading(true, 'Realizando login...');
    try {
      const isAuthenticated = await login(email, password, history);

      if (isAuthenticated) {
        Toast(MessageTypes.success, 'Logado com sucesso');
        loading(false);
        window.location.reload(false);
        return <Redirect to="/"/>
      }

    } catch (e) {
      Toast(MessageTypes.error, e?.response?.data?.error_message || 'Error ao realizar login');
      loading(false);
    }
  }

  return (
    <Styled.Container>

      <Styled.Row>
        <Styled.Col>
          <Styled.Logo src={logo}/>
          <Styled.SubTitle>conversor</Styled.SubTitle>
        </Styled.Col>

        <Styled.Col>
          <Styled.Form onSubmit={onSubmit}>
            <Styled.FormGroup controlId="email">
              <Styled.FormControl
                autoFocus
                type="email"
                value={email}
                placeholder="E-mail"
                onChange={e => setEmail(e.target.value)}
              />
            </Styled.FormGroup>
            <Styled.FormGroup controlId="password">
              <Styled.FormControl
                value={password}
                onChange={e => setPassword(e.target.value)}
                type="password"
                placeholder="Senha"
              />
            </Styled.FormGroup>
            <Styled.SubmitButton
              type="submit"
              disabled={!validateForm()}>
              Acessar
            </Styled.SubmitButton>
          </Styled.Form>
        </Styled.Col>

      </Styled.Row>
    </Styled.Container>
  );
}

export default Login;
