import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

import * as Styled from './styles'
import { getCustomers } from '../../services/Customers';
export default function Customers({ setSelectedClientId }) {
  const [email, setEmail] = useState('');
  const [customersList, setCustomersList] = useState([]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setSelectedClientId(null);
    setCustomersList([]);
    const data = await getCustomers(email);
    setCustomersList(data);
    if (data[0]) {
      setSelectedClientId(data[0].id ?? null)
    }
  };

  return (
    <>
      <Form onSubmit={onSubmit}>
        <Form.Control
          type="email"
          name="email"
          placeholder="Informe o email"
          onChange={e => setEmail(e.target.value)}
        />
        <Styled.SubmitButton variant="info" type="submit"> Buscar </Styled.SubmitButton>
      </Form>
      {customersList.length > 0 && (
        <Form.Group>
          <Form.Label>Selecione um cliente</Form.Label>
          <Form.Control as="select" onChange={e => setSelectedClientId(e.target.value)}>
            {customersList.map((e) => <option value={e.id}>{e.name + ' - ' + e.application}</option>)}
          </Form.Control>
        </Form.Group>
      )}
    </>
  );
}
