import React from 'react';

import {
  SpanItem
} from './styles';

export default function Span({ errorType }) {
  const type = errorType === '1' ? 'Campos Ausentes' : 'Atributos Ausentes';
  return (
     <SpanItem>{type}</SpanItem>
  );
}


