import styled from 'styled-components';
import {Button} from 'react-bootstrap'

export const Section = styled.section``;
export const Input = styled.input``;
export const Paragraph = styled.p``;

export const SubmitButton = styled(Button)`
  position: auto !important;
  margin-top: 15px;
  width: 150px;
`;

export const Dropzone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  margin-top: 10px;

  &:focus, &:hover {
    border-color: #2196f3;
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.6;
  }
`;
